import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
import './App.css';

import MainPage from './components/MainPage';
import Footer from './components/Footer';
import TermsPage from './pages/Terms';
import PrivacyPage from './pages/Privacy';
import SupportPage from './pages/Support';
import CancellationPage from './pages/Cancellation';

function App() {
  const hasTrackedHomePageViewed = useRef(false);

  useEffect(() => {
    if (!hasTrackedHomePageViewed.current) {
      mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: true, track_pageview: false, persistence: 'localStorage' });
      mixpanel.track('HomePageViewed');
      hasTrackedHomePageViewed.current = true;
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Echovane | Collect In-Depth Product Feedback Effortlessly</title>
          <meta name="description" content="Echovane is an AI-driven market research platform that helps you gather in-depth consumer feedback and insights efficiently." />

        </Helmet>
        
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/cancellation" element={<CancellationPage />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
