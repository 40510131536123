import React from 'react';
import { Button } from '@mui/material';
import logo from '../echovane_logo.svg'; // Adjust the path if needed
import { Link } from 'react-router-dom'; 

function Header() {
  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };

  return ( 
    <header className="nav-bar">
     <Link   to="/">
        <img src={logo} alt="Echovane" className="logo-link" />
      </Link>
      <div className="button-container">
        <Button
          variant="contained"
          className="signup-button"
          onClick={() => handleButtonClick('https://dashboard.echovane.com/login?screen_hint=signup')}
        >
          Get Started for Free
        </Button>
        <Button
          variant="contained"
          className="signup-button-login"
          onClick={() => handleButtonClick('https://dashboard.echovane.com/')}
        >
          Login
        </Button>
      </div>
    </header>
  );
}

export default Header;
