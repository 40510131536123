import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header'; 

function CancellationPage() {
  return (

    <div className='page-wrapper'><Header /><main className="policy-page">
          <Helmet>
              <title>Echovane - Cancellation & Refund Policy</title>
              <meta name="description" content="Learn about Echovane’s cancellation and refund policies for AI-based market research services." />
          </Helmet>
          <h1>Cancellation & Refund Policy</h1>

          <h2>Refund Eligibility</h2>
          <p>We strive to provide the best experience possible. Refunds for subscription fees may be issued under the following conditions:</p>
          <ul>
              <li>If you experience a technical issue that prevents you from using the platform, and we are unable to resolve it within a reasonable timeframe.</li>
              <li>If you cancel during a free trial period (if applicable) before the trial ends.</li>
              <li>Refund requests must be submitted within 7 days of the billing date.</li>
          </ul>
          <p>Refunds will not be issued for partial months of service or if the cancellation occurs after the billing period has ended.</p>

          <h2>How to Cancel Services</h2>
          <p>You can cancel your subscription at any time by following these steps:</p>
          <ol>
              <li>Log in to your Echovane account.</li>
              <li>Navigate to the <strong>Billing Settings</strong> section.</li>
              <li>Click on <strong>Cancel Subscription</strong> and follow the prompts to confirm your cancellation.</li>
          </ol>
          <p>Cancellations take effect at the end of the current billing cycle. You will continue to have access to the service until the end of the paid period.</p>

          <h2>Processing Refunds</h2>
          <p>If your refund request is approved, the refund will be processed as follows:</p>
          <ul>
              <li><strong>Timeframe:</strong> Refunds are typically processed within 5-10 business days, depending on your payment provider.</li>
              <li><strong>Method:</strong> Refunds will be issued to the original payment method used at the time of purchase.</li>
              <li><strong>Deductions:</strong> Certain deductions may apply, such as transaction fees imposed by Stripe or other payment processors.</li>
          </ul>
          <p>If you have questions about refunds or need assistance, please contact us at <strong>contact@echovane.com</strong>.</p>
      </main></div>
  );
}

export default CancellationPage;
